import { IDocumentUpload } from 'models/documentUpload';
import { connect } from 'react-redux';
import { change, formValueSelector, reset } from 'redux-form';
import { getUserProfileState } from 'sections/common/redux/selector';
import { AppState } from 'state/app-state';
import {
  getErrorsState,
  getDocumentUploadEventState,
  getDocumentUploadItemsState,
  getDocumentUploadLoadingState,
  getDocumentUploadNotifyState
} from './redux/selector';
import { downloadFile, getHistory, resetDocumentUploadState,postDocument} from './redux/thunk';
import { DocumentUpload } from './documentUpload';

const selector = formValueSelector('DocumentUploadForm');

const mapStateToProps = (state: AppState) => {
  return {    
    loading: getDocumentUploadLoadingState(state),
    event: getDocumentUploadEventState(state),
    errors: getErrorsState(state),
    userProfile: getUserProfileState(state),
    notify: getDocumentUploadNotifyState(state),
    items: getDocumentUploadItemsState(state)
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    resetDocumentUploadState: (): void => dispatch(resetDocumentUploadState()),
    postDocument: (data: IDocumentUpload) => dispatch(postDocument(data)),
    reset: () => dispatch(reset('DocumentUploadForm')),
    getHistory: () => dispatch(getHistory()),
    downloadFile: (id: string, fileName: string) => dispatch(downloadFile(id, fileName))
  };
};

export const DocumentUploadContainer = connect(mapStateToProps, mapDispatchToProps)(DocumentUpload);
