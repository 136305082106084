export const actionTypes = {
    SET_DOCUMENTUPLOAD_LOADING_TRUE: 'SET_DOCUMENTUPLOAD_LOADING_TRUE',
    RESET_DOCUMENTUPLOAD_STATE: 'RESET_DOCUMENTUPLOAD_STATE',
    DOCUMENT_UPLOAD_SUCCESS: 'DOCUMENT_UPLOAD_SUCCESS',
    DOCUMENT_UPLOAD_ERROR: 'DOCUMENT_UPLOAD_ERROR',
    GET_HISTORY_SUCCESS: 'GET_HISTORY_SUCCESS',
    GET_HISTORY_ERROR: 'GET_HISTORY_ERROR',
    DOWNLOAD_SUCCESS: 'DOWNLOAD_SUCCESS',
    DOWNLOAD_ERROR: 'DOWNLOAD_ERROR'
  };
  