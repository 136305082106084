export interface ITMUpload {
  tm1: File;
  talentCoach: File;
  financialYear: number;
  calculationName: string;
  startDate: Date;
  endDate: Date;
}

export enum UploadType {
  TM1 = 'TM1',
  TalentCoach = 'TalentCoach',
  UserGuide = 'UserGuide',
  NFOGuide = 'NFOGuide'
}

export interface ITMUploadItem {
  id: string;
  calculationName: string;
  startDate: string;
  endDate: string;
  generatedBy: string;
  status: string;
  remarks: string;
  tm1FileId: string;
  talentCoachFileId: string;
}
