export interface IError {
  Code: string;
  Message: string;
}

export const ErrorCode = {
  uniqueEmployeeId: 'employee-id-must-be-unique',
  uniqueEmployeeEmail: 'employee-email-must-be-unique',
  differentLineManager: 'line-manager-cannot-be-changed-user-has-active-scorecard-with-current-date',
  uniqueSimulationName: 'bonus-calculation-simulation-name-must-be-unique',
  unSupportedTM1File: 'tm1files-unsupported-extension',
  unSupportedTCFile: 'tcfiles-unsupported-extension',
  inValidTM1File: 'invalid-tm1file',
  unSupportedFile: 'files-unsupported-extension',
  inValidFile: 'invalid-file'
};
