import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import * as React from 'react';
import { CloudDownloadOutlined } from '@material-ui/icons';
import { IDocumentUploadItem } from 'models/documentUpload';
import { EventType } from 'models/EventType';
import { CircularProgress } from '@material-ui/core';

interface DocumentUploadHistoryProps extends InjectedIntlProps {
  loading: boolean;
  event: EventType;
  items: IDocumentUploadItem[];
  downloadFile: (id: string, fileName: string) => void;
}

const DocumentUploadHistory: React.FunctionComponent<DocumentUploadHistoryProps> = (props: DocumentUploadHistoryProps) => {
  const onDownload = (event: any, id: string, fileName: string) => {
    event.preventDefault();
    if (id != null) {
      props.downloadFile(id, fileName);
    }
  };
  return (
    <div>
      <h2 className="subheading medium-fw">
        <FormattedMessage id="documentUpload.history.title" />
      </h2>
      {props.loading && props.event === EventType.getData ? (
        <div className="text-center">
          <CircularProgress className="sbl-circ-path" thickness={4} value={100} />
        </div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="documentUpload.history.name" />
              </th>
              <th>
                <FormattedMessage id="documentUpload.history.documentType" />
              </th>
              <th>
                <FormattedMessage id="documentUpload.history.updatedBy" />
              </th>
              <th>
                <FormattedMessage id="documentUpload.history.updatedOn" />
              </th>
              <th>
                <FormattedMessage id="documentUpload.history.download" />
              </th>
            </tr>
          </thead>
          <tbody>
            {props.items &&
              props.items.map((item: IDocumentUploadItem, index: number) => (
                <tr key={index}>
                  <td>{item.name}</td>                  
                  <td>{props.intl.formatMessage({ id: `documentUpload.${item.documentType}`})}</td>
                  <td>{item.createdBy}</td>
                  <td>{item.createdOn}</td>
                  <td>
                    <button
                      id="downloadDocumentData"
                      type="button"
                      className="btn btn-outline icoBtn"
                      onClick={(e) => onDownload(e, item.id, `${item.documentType}.pdf`)}
                    >
                      <CloudDownloadOutlined />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export const DocumentUploadHistoryComponent = injectIntl(DocumentUploadHistory);
