import { EventType } from 'models/EventType';
import { Notify } from 'models/Notify';
import { IDocumentUpload } from 'models/documentUpload';
import { Dispatch } from 'redux';
import { get, getFile, post, postFile } from 'services/api-clients';
import { actionTypes } from './actions';

const setloadingTrue = (event: EventType) => {
  return {
    type: actionTypes.SET_DOCUMENTUPLOAD_LOADING_TRUE,
    payload: {
      event: event
    }
  };
};

const resetDocumentUploadStateDispatch = () => {
  return {
    type: actionTypes.RESET_DOCUMENTUPLOAD_STATE
  };
};

export const resetDocumentUploadState = () => {
  return (dispatch: Dispatch) => {
    dispatch(resetDocumentUploadStateDispatch());
  };
};

export const postDocument = (data: IDocumentUpload) => {
  const event = EventType.save;
  const formData = new FormData();
  formData.append('financialYear', data.financialYear.toString());
  formData.append('file', data.document);
  formData.append('documentType', data.type);

  return (dispatch: Dispatch) => {
    dispatch(setloadingTrue(event));
    postFile(`/Documentation`, formData)
      .then((response) => {
        dispatch(documentUploadSuccess(event));
      })
      .catch((error) => {
        if (error?.response?.status === 409) {
          return dispatch(documentUploadError(error?.response?.data?.Errors ?? [], event, Notify.none));
        }
        dispatch(documentUploadError([], event, Notify.internalServerError));
      });
  };
};
const documentUploadSuccess = (event: EventType) => {
  return {
    type: actionTypes.DOCUMENT_UPLOAD_SUCCESS,
    payload: {
      event: event
    }
  };
};

const documentUploadError = (errors: any, event: EventType, notify: Notify) => {
  return {
    type: actionTypes.DOCUMENT_UPLOAD_ERROR,
    payload: {
      notify: notify,
      event: event,
      errors: errors
    }
  };
};


const getHistorySuccess = (data: any) => {
  return {
    type: actionTypes.GET_HISTORY_SUCCESS,
    payload: {
      items: data
    }
  };
};

const getHistoryError = (notify: Notify) => {
  return {
    type: actionTypes.GET_HISTORY_ERROR,
    payload: {
      notify: notify
    }
  };
};

export const getHistory = () => {
  return (dispatch: Dispatch) => {
    dispatch(setloadingTrue(EventType.getData));
    get(`/Documentation`)
      .then((response) => {
        dispatch(getHistorySuccess(response.data?.documents));
      })
      .catch((error) => {
        dispatch(getHistoryError(Notify.internalServerError));
      });
  };
};

const downloadFileSuccess = () => {
  return {
    type: actionTypes.DOWNLOAD_SUCCESS
  };
};

const downloadFileError = (notify: Notify) => {
  return {
    type: actionTypes.DOWNLOAD_ERROR,
    payload: {
      notify: notify
    }
  };
};

export const downloadFile = (id: string, fileName: string) => {
  
  return (dispatch: Dispatch) => {
    dispatch(setloadingTrue(EventType.download));
    getFile(`/Documentation/${id}`, fileName)
      .then((response) => {
        dispatch(downloadFileSuccess());
      })
      .catch((error) => {
        dispatch(downloadFileError(Notify.internalServerError));
      });
  };
};
