import React from 'react';
import { FileUploaderPresentationalComponent } from './FileUploaderPresentationalComponent';
import { FileUploadMode } from '../../models/attachment';
import './attachment.scss';
import { UploadType } from 'models/tmUpload';

interface State {
  dragging: boolean;
  file: File | null;
  fileBinaries: any;
  fileErrorMessage: any | '';
}
interface Props {
  id: string;
  isLoading: boolean | false;
  file: File;
  uploadType: UploadType;
  allowedFileTypes: string;
  invalidFileTypeMessage: string;
  fileUploadMode: FileUploadMode | FileUploadMode.SingleFileSingleUpload;
  singleColumn: boolean | true;
  uploadFile: (file: File, type: UploadType) => void;
  deleteFile: (type: UploadType) => void;
  isDisabled: boolean;
  errorKey?: string;
}

class FileUploader extends React.Component<Props, State> {
  static counter = 0;
  fileUploaderInput: HTMLInputElement | null = null;

  constructor(props: Props) {
    super(props);
    this.state = { dragging: false, file: null, fileBinaries: '', fileErrorMessage: '' };
  }

  dragEventCounter = 0;
  dragenterListener = (event: React.DragEvent<HTMLDivElement>) => {
    this.overrideEventDefaults(event);
    this.dragEventCounter++;
    if (event.dataTransfer.items && event.dataTransfer.items[0]) {
      this.setState({ dragging: true });
    } else if (event.dataTransfer.types && event.dataTransfer.types[0] === 'Files') {
      // This block handles support for IE - if you're not worried about
      // that, you can omit this
      this.setState({ dragging: true });
    }
  };

  dragleaveListener = (event: React.DragEvent<HTMLDivElement>) => {
    this.overrideEventDefaults(event);
    this.dragEventCounter--;

    if (this.dragEventCounter === 0) {
      this.setState({ dragging: false });
    }
  };

  dropListener = (event: React.DragEvent<HTMLDivElement>) => {
    this.overrideEventDefaults(event);
    this.dragEventCounter = 0;
    this.setState({ dragging: false });
    const file: any = event.dataTransfer.files;
    this.readFile(file);
  };

  onFileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = event.target.files;
    this.readFile(file);
  };

  onSelectFileClick = () => {
    this.fileUploaderInput && this.fileUploaderInput.click();
  };

  overrideEventDefaults = (event: Event | React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  readFile = (file: any) => {
    if (file && file[0]) {
      if (this.props.allowedFileTypes) {
        const filenameLower = file[0].name.toLowerCase();
        let isValid = false;
        const allowedFiles = this.props.allowedFileTypes.toLowerCase().split(',');
        allowedFiles.forEach((allowedFile) => {
          if (filenameLower.endsWith(allowedFile)) {
            isValid = true;
          }
        });

        if (!isValid) {
          this.setState({
            file: null,
            fileBinaries: null,
            fileErrorMessage: this.props.invalidFileTypeMessage
          });
          return;
        }
      }

      this.props.uploadFile(file[0], this.props.uploadType);

      if (this.fileUploaderInput) {
        this.fileUploaderInput.value = '';
      }
    }
  };

  componentDidMount() {
    window.addEventListener('dragover', (event: Event) => {
      this.overrideEventDefaults(event);
    });
    window.addEventListener('drop', (event: Event) => {
      this.overrideEventDefaults(event);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('dragover', this.overrideEventDefaults);
    window.removeEventListener('drop', this.overrideEventDefaults);
  }

  render() {
    return (
      <FileUploaderPresentationalComponent
        dragging={this.state.dragging}
        fileErrorMessage={this.state.fileErrorMessage}
        file={this.props.file}
        uploadType={this.props.uploadType}
        fileUploadMode={this.props.fileUploadMode}
        isLoading={this.props.isLoading}
        onSelectFileClick={this.onSelectFileClick}
        onDrag={this.overrideEventDefaults}
        onDragStart={this.overrideEventDefaults}
        onDragEnd={this.overrideEventDefaults}
        onDragOver={this.overrideEventDefaults}
        onDragEnter={this.dragenterListener}
        onDragLeave={this.dragleaveListener}
        onDrop={this.dropListener}
        deleteFile={this.props.deleteFile}
        isDisabled={this.props.isDisabled}
        errorKey={this.props.errorKey}
      >
        <input
          ref={(el) => (this.fileUploaderInput = el)}
          type="file"
          accept={this.props.allowedFileTypes}
          className="file-uploader__input hide"
          onChange={this.onFileChanged}
          disabled={this.props.isDisabled}
        />
      </FileUploaderPresentationalComponent>
    );
  }
}

export default FileUploader;
