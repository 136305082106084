import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import './documentUpload.scss';
import './../formsSearch/formsSearch.scss';
import './../../common/layout/default/defaultLayout.scss';
import { useEffect, useState } from 'react';
import { DocumentUploadForm } from './components/documentUploadForm';
import { Notify } from 'models/Notify';
import { EventType } from 'models/EventType';
import { IDocumentUpload, IDocumentUploadItem } from 'models/documentUpload';
import { IError } from 'models/error';
import { toastr } from 'react-redux-toastr';
import { User, userPrivileges } from 'models/user';
import { appHistory } from 'app-history';
import { Privileges } from 'models/privilege';
import routes from 'screens/routes';
import { DocumentUploadHistoryComponent } from './components/documentUploadHistory';

interface DocumentUploadProps extends InjectedIntlProps {
  notify: Notify;
  loading: boolean;
  event: EventType;
  errors: Array<IError>;
  userProfile: User;
  items: IDocumentUploadItem[];
  resetDocumentUploadState: () => void;
  postDocument: (data: IDocumentUpload) => void;
  reset: () => void;
  getHistory: () => void;
  downloadFile: (id: string, fileName: string) => void;
}

const DocumentUploadComponent: React.FC<DocumentUploadProps> = (props) => {
  const { intl } = props;
  const history = appHistory();

  const [documentData, setDocumentData] = useState<File | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    props.getHistory();
  }, []);

  useEffect(() => {
    if (props.notify === Notify.success && props.event === EventType.save) {
      toastr.success(intl.formatMessage({ id: 'documentUpload.uploadSuccessful' }), '');
      setDocumentData(null);
      props.resetDocumentUploadState();
      props.reset();
      props.getHistory();
    }
    if (props.notify === Notify.internalServerError || props.notify === Notify.error) {
      toastr.error(intl.formatMessage({ id: 'global.errorOccured' }), '');
    }
  }, [props.notify, props.event]);

  React.useEffect(() => {
    if (props.userProfile && props.userProfile.id !== -1) {
      const userPrivleges = userPrivileges(props.userProfile);
      if (!userPrivleges.contains(Privileges.UpdateDocumentation)) {
        history.push({
          pathname: `${routes.accessDenied}`
        });
      }
    }
  }, [props.userProfile]);

  return (
    <>
      <div className="container-fluid">
        <div className="row breadcrumbSC">
          <div className="col-9">
            <h1 className="medium-fw">
              <FormattedMessage id="documentUpload.title" />
            </h1>
          </div>
          <div className="col-3 cta right_align">
          
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="wd-100">
          <div className="row">
            <div className="col-md-9">
              <div className="custom-wrap bg-white">
                <DocumentUploadForm
                  loading={props.loading}
                  event={props.event}
                  documentData={documentData}
                  errors={props.errors}
                  postDocument={props.postDocument}
                  setDocumentData={setDocumentData}
                />
                <DocumentUploadHistoryComponent
                  loading={props.loading}
                  event={props.event}
                  items={props.items}
                  downloadFile={props.downloadFile}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const DocumentUpload = injectIntl(DocumentUploadComponent);
