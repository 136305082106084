import { defineMessages } from 'react-intl';
export default defineMessages<any>({
  'global.logout': {
    id: 'global.logout',
    defaultMessage: 'Are you sure you want to Log Out?'
  },
  'global.save': {
    id: 'global.save',
    defaultMessage: 'Save'
  },
  'global.next': {
    id: 'global.next',
    defaultMessage: 'Next'
  },
  'global.submit': {
    id: 'global.submit',
    defaultMessage: 'Submit'
  },
  'global.buttonYes': {
    id: 'global.buttonYes',
    defaultMessage: 'Yes'
  },
  'global.buttonNo': {
    id: 'global.buttonNo',
    defaultMessage: 'No'
  },
  'global.confirmation': {
    id: 'global.confirmation',
    defaultMessage: 'Confirmation'
  },
  'global.continue': {
    id: 'global.continue',
    defaultMessage: 'Continue'
  },
  'global.dirtyCheck': {
    id: 'global.dirtyCheck',
    defaultMessage: 'You have unsaved changes on this page. Do you want to leave this page and discard your changes?'
  },
  'global.dropHere': {
    id: 'global.dropHere',
    defaultMessage: 'Drop Here'
  },
  'global.title': {
    id: 'global.title',
    defaultMessage: 'Energy & Sustainability Assessment Tool'
  },
  'global.Blank': {
    id: 'global.Blank',
    defaultMessage: 'Blank'
  },
  'global.Yes': {
    id: 'global.Yes',
    defaultMessage: 'Yes'
  },
  'global.No': {
    id: 'global.No',
    defaultMessage: 'No'
  },
  'global.comment': {
    id: 'global.comment',
    defaultMessage: 'Comment'
  },
  'global.addComment': {
    id: 'global.addComment',
    defaultMessage: 'Add Comment'
  },
  'global.pageNavigation.recordsPerPage': {
    id: 'global.pageNavigation.recordsPerPage',
    defaultMessage: 'Records Per Page'
  },
  'global.pageNavigation.pagesHyphen': {
    id: 'global.pageNavigation.pagesHyphen',
    defaultMessage: ' of '
  },
  'global.pageNavigation.nextPage': {
    id: 'global.pageNavigation.nextPage',
    defaultMessage: '>'
  },
  'global.pageNavigation.prevPage': {
    id: 'global.pageNavigation.prevPage',
    defaultMessage: '<'
  },
  'global.errorOccured': {
    id: 'global.errorOccured',
    defaultMessage: 'An error occured. Please contact administrator.'
  },
  'global.percentageSign': {
    id: 'global.percentageSign',
    defaultMessage: '%'
  },
  'global.dolarSign': {
    id: 'global.dolarSign',
    defaultMessage: '$'
  },
  'global.draftSaved': {
    id: 'global.draftSaved',
    defaultMessage: 'Draft saved successfully.'
  },
  'global.edit': {
    id: 'global.edit',
    defaultMessage: 'Edit'
  },
  'global.approve': {
    id: 'global.approve',
    defaultMessage: 'Approve'
  },
  'global.reject': {
    id: 'global.reject',
    defaultMessage: 'Reject'
  },
  'global.confirmApprove': {
    id: 'global.confirmApprove',
    defaultMessage: 'Are you sure you want to approve?'
  },
  'global.confirmReject': {
    id: 'global.confirmReject',
    defaultMessage: 'Are you sure you want to reject?'
  },
  'global.requiredComment': {
    id: 'global.requiredComment',
    defaultMessage: 'Comment is required'
  },
  'global.submitSuccess': {
    id: 'global.submitSuccess',
    defaultMessage: 'Successfully submitted for approval'
  },
  'global.nFOSavedSuccess': {
    id: 'global.nFOSavedSuccess',
    defaultMessage: 'NFO saved successfully.'
  },
  'global.nFOSubmittedSuccess': {
    id: 'global.nFOSubmittedSuccess',
    defaultMessage: 'NFO submitted successfully.'
  },
  'global.editSuccess': {
    id: 'global.editSuccess',
    defaultMessage: 'Edited successfully'
  },
  'global.approved': {
    id: 'global.approved',
    defaultMessage: 'Approved successfully'
  },
  'global.rejected': {
    id: 'global.rejected',
    defaultMessage: 'Rejected successfully'
  },
  'global.cancel': {
    id: 'global.cancel',
    defaultMessage: 'Cancel'
  },
  'global.unformattedSave': {
    id: 'global.unformattedSave',
    defaultMessage: 'Save'
  },
  'global.unAuthorisedAccess': {
    id: 'global.unAuthorisedAccess',
    defaultMessage: 'You are not authorised. Please get in touch with the administrator.'
  },
  'global.hypen': {
    id: 'global.hypen',
    defaultMessage: '-'
  },
  'global.achievementSubmitted': {
    id: 'global.achievementSubmitted',
    defaultMessage: 'Achievement submitted successfully'
  },
  'global.accessDenied': {
    id: 'global.accessDenied',
    defaultMessage: 'Access Denied'
  },
  'global.notFound': {
    id: 'global.notFound',
    defaultMessage: 'Not Found'
  },
  'global.notFoundMessage': {
    id: 'global.notFoundMessage',
    defaultMessage: 'Requested resource does not exist'
  },
  'home.title': {
    id: 'home.title',
    defaultMessage: 'Title'
  },
  'home.welcome': {
    id: 'home.welcome',
    defaultMessage: 'Welcome'
  },
  'formsList.title': {
    id: 'formsList.title',
    defaultMessage: 'Financial Objectives Forms'
  },
  'formsList.item.datesHyphen': {
    id: 'formsList.item.datesHyphen',
    defaultMessage: ' to '
  },
  'formsList.item.approved': {
    id: 'formsList.item.approved',
    defaultMessage: 'Approved'
  },
  'formsList.item.pendingApproval': {
    id: 'formsList.item.pendingApproval',
    defaultMessage: 'Pending approval'
  },
  'formsList.item.Approved': {
    id: 'formsList.item.Approved',
    defaultMessage: 'Approved'
  },
  'formsList.item.Rejected': {
    id: 'formsList.item.Rejected',
    defaultMessage: 'Rejected'
  },
  'formsList.item.PendingApproval': {
    id: 'formsList.item.PendingApproval',
    defaultMessage: 'Pending approval'
  },
  'formsList.item.FinanceDirector': {
    id: 'formsList.item.FinanceDirector',
    defaultMessage: 'Finance Director'
  },
  'formsList.search.newForm': {
    id: 'formsList.search.newForm',
    defaultMessage: 'New Form'
  },
  'formsList.search.showing': {
    id: 'formsList.search.showing',
    defaultMessage: 'Showing: '
  },
  'formsList.search.clearFilters': {
    id: 'formsList.search.clearFilters',
    defaultMessage: 'Clear filters'
  },
  'formsList.search.sorting.name_asc': {
    id: 'formsList.search.sorting.name_asc',
    defaultMessage: 'Sort by Employee Name Ascending'
  },
  'formsList.search.sorting.name_desc': {
    id: 'formsList.search.sorting.name_desc',
    defaultMessage: 'Sort by Employee Name Descending'
  },
  'formsList.search.sorting.surname_asc': {
    id: 'formsList.search.sorting.surname_asc',
    defaultMessage: 'Sort by Employee Surname Ascending'
  },
  'formsList.search.sorting.surname_desc': {
    id: 'formsList.search.sorting.surname_desc',
    defaultMessage: 'Sort by Employee Surname Descending'
  },
  'formsList.search.sorting.start_date_asc': {
    id: 'formsList.search.sorting.start_date_asc',
    defaultMessage: 'Sort by Start Date Ascending'
  },
  'formsList.search.sorting.start_date_desc': {
    id: 'formsList.search.sorting.start_date_desc',
    defaultMessage: 'Sort by Start Date Descending'
  },
  'formsList.search.sorting.end_date_asc': {
    id: 'formsList.search.sorting.end_date_asc',
    defaultMessage: 'Sort by End Date Ascending'
  },
  'formsList.search.sorting.end_date_desc': {
    id: 'formsList.search.sorting.end_date_desc',
    defaultMessage: 'Sort by End Date Descending'
  },
  'formsList.search.sorting.manager_first_name_asc': {
    id: 'formsList.search.sorting.manager_first_name_asc',
    defaultMessage: 'Sort by Manager Name Ascending'
  },
  'formsList.search.sorting.manager_first_name_desc': {
    id: 'formsList.search.sorting.manager_first_name_desc',
    defaultMessage: 'Sort by Manager Name Descending'
  },
  'formsList.search.sorting.manager_last_name_asc': {
    id: 'formsList.search.sorting.manager_last_name_asc',
    defaultMessage: 'Sort by Manager Last Name Ascending'
  },
  'formsList.search.sorting.manager_last_name_desc': {
    id: 'formsList.search.sorting.manager_last_name_desc',
    defaultMessage: 'Sort by Manager Last Name Descending'
  },
  'formsList.search.noRecords': {
    id: 'formsList.search.noRecords',
    defaultMessage: 'No records found'
  },
  accessDenied: {
    id: 'accessDenied',
    defaultMessage: 'Access Denied'
  },
  notFound: {
    id: 'notFound',
    defaultMessage: 'Not Found'
  },
  notFoundMessage: {
    id: 'notFoundMessage',
    defaultMessage: 'Requested resource does not exist'
  },
  'loader.pleaseWait': {
    id: 'loader.pleaseWait',
    defaultMessage: 'Loading. Please wait...'
  },
  'translation.notfound': {
    id: 'translation.notfound',
    defaultMessage: 'Not Found'
  },
  'networkConnection.title.online': {
    id: 'networkConnection.title.online',
    defaultMessage: 'Online'
  },
  'networkConnection.title.offline': {
    id: 'networkConnection.title.offline',
    defaultMessage: 'Offline'
  },
  'networkConnection.message.online': {
    id: 'networkConnection.message.online',
    defaultMessage: 'You are working online.'
  },
  'networkConnection.message.offline': {
    id: 'networkConnection.message.offline',
    defaultMessage: 'You are working offline.'
  },
  'financialObjective.NewFOFormTitle': {
    id: 'financialObjective.NewFOFormTitle',
    defaultMessage: 'Financial and Non-Financial Objectives Form'
  },
  'financialObjective.UserDetailsTitle': {
    id: 'financialObjective.UserDetailsTitle',
    defaultMessage: 'Employee Details and Period'
  },
  'financialObjective.SearchLabel': {
    id: 'financialObjective.SearchLabel',
    defaultMessage: 'Search by Employee ID, Name or Email'
  },
  'financialObjective.UserID': {
    id: 'financialObjective.UserID',
    defaultMessage: 'ID'
  },
  'financialObjective.UserFirstName': {
    id: 'financialObjective.UserFirstName',
    defaultMessage: 'First Name'
  },
  'financialObjective.UserLastName': {
    id: 'financialObjective.UserLastName',
    defaultMessage: 'Last Name'
  },
  'financialObjective.UserEmail': {
    id: 'financialObjective.UserEmail',
    defaultMessage: 'Email'
  },
  'financialObjective.UserTitle': {
    id: 'financialObjective.UserTitle',
    defaultMessage: 'Title/Position'
  },
  'financialObjective.UserDetails': {
    id: 'financialObjective.UserDetails',
    defaultMessage: 'Employee Details'
  },
  'financialObjective.UserManagerName': {
    id: 'financialObjective.UserManagerName',
    defaultMessage: 'Manager Name'
  },
  'financialObjective.UserManagerTitle': {
    id: 'financialObjective.UserManagerTitle',
    defaultMessage: 'Title/Position'
  },
  'financialObjective.UserManagerEmailId': {
    id: 'financialObjective.UserManagerEmailId',
    defaultMessage: 'Email'
  },
  'financialObjective.ManagerDetails': {
    id: 'financialObjective.ManagerDetails',
    defaultMessage: 'Manager Details'
  },
  'financialObjective.FormPeriodLabel': {
    id: 'financialObjective.FormPeriodLabel',
    defaultMessage: 'Financial Objectives Form'
  },
  'financialObjective.startDate': {
    id: 'financialObjective.startDate',
    defaultMessage: 'StartDate'
  },
  'financialObjective.endDate': {
    id: 'financialObjective.endDate',
    defaultMessage: 'EndDate'
  },
  'financialObjective.message':{
    id: 'financialObjective.message',
    defaultMessage:'Each NFO is assessed as either a PASS or FAIL and carries an equal weighting. Responsibility for proof of PASS/FAIL remains with the Line Manager/Employee concerned when assessing the NFOs. For further information, please see the guidance document attached.'
  },
  'financialObjective.status': {
    id: 'financialObjective.status',
    defaultMessage: 'Status'
  },
  'financialObjective.bonusScheme': {
    id: 'financialObjective.bonusScheme',
    defaultMessage: 'Bonus Scheme'
  },
  'financialObjective.reasonForStatusChange': {
    id: 'financialObjective.reasonForStatusChange',
    defaultMessage: 'Reason for status change'
  },
  'financialObjective.selectStatus': {
    id: 'financialObjective.selectStatus',
    defaultMessage: 'Select'
  },
  'financialObjective.levelMapping.manualOverrideOtherCommentJustification': {
    id: 'financialObjective.levelMapping.manualOverrideOtherCommentJustification',
    defaultMessage: 'Other Justification Comment'
  },
  'financialObjective.overlap.title': {
    id: 'financialObjective.overlap.title',
    defaultMessage: 'Overlapping FO Forms'
  },
  'financialObjective.overlap.period': {
    id: 'financialObjective.overlap.period',
    defaultMessage: 'Period'
  },
  'financialObjective.overlap.employee': {
    id: 'financialObjective.overlap.employee',
    defaultMessage: 'Employee'
  },
  'financialObjective.overlap.manager': {
    id: 'financialObjective.overlap.manager',
    defaultMessage: 'Manager'
  },
  'financialObjective.overlap.to': {
    id: 'financialObjective.overlap.to',
    defaultMessage: 'to'
  },
  'financialObjective.levelMapping.title': {
    id: 'financialObjective.levelMapping.title',
    defaultMessage: 'Level Mappings'
  },
  'financialObjective.levelMapping.subSegment': {
    id: 'financialObjective.levelMapping.subSegment',
    defaultMessage: 'Sub Segment'
  },
  'financialObjective.levelMapping.division': {
    id: 'financialObjective.levelMapping.division',
    defaultMessage: 'Division'
  },
  'financialObjective.levelMapping.vicePres': {
    id: 'financialObjective.levelMapping.vicePres',
    defaultMessage: 'Vice Pres'
  },
  'financialObjective.levelMapping.subDivision': {
    id: 'financialObjective.levelMapping.subDivision',
    defaultMessage: 'Subdivision'
  },
  'financialObjective.levelMapping.team': {
    id: 'financialObjective.levelMapping.team',
    defaultMessage: 'Team'
  },
  'financialObjective.levelMapping.clients': {
    id: 'financialObjective.levelMapping.clients',
    defaultMessage: 'Clients'
  },
  'financialObjective.levelMapping.businessSegment': {
    id: 'financialObjective.levelMapping.businessSegment',
    defaultMessage: 'Business Segment'
  },
  'financialObjective.levelMapping.globalRegion': {
    id: 'financialObjective.levelMapping.globalRegion',
    defaultMessage: 'Global Region'
  },
  'financialObjective.levelMapping.region': {
    id: 'financialObjective.levelMapping.region',
    defaultMessage: 'Region'
    },
    'financialObjective.levelMapping.country': {
        id: 'financialObjective.levelMapping.country',
        defaultMessage: 'Country'
    },
  'financialObjective.levelMapping.managingOffice': {
    id: 'financialObjective.levelMapping.managingOffice',
    defaultMessage: 'Managing Office'
  },
  'financialObjective.levelMapping.client': {
    id: 'financialObjective.levelMapping.client',
    defaultMessage: 'Client'
  },
  'financialObjective.levelMapping.location': {
    id: 'financialObjective.levelMapping.location',
    defaultMessage: 'Location'
  },
  'financialObjective.levelMapping.lineOfBusiness': {
    id: 'financialObjective.levelMapping.lineOfBusiness',
    defaultMessage: 'Line of Business'
  },
  'financialObjective.levelMapping.functionalUnit': {
    id: 'financialObjective.levelMapping.functionalUnit',
    defaultMessage: 'Functional Unit'
  },  
  'financialObjective.levelMapping.additionalComments': {
    id: 'financialObjective.levelMapping.additionalComments',
    defaultMessage: 'Additional Comments'
  },
  'financialObjective.levelMapping.scope': {
    id: 'financialObjective.levelMapping.scope',
    defaultMessage: 'Scope'
  },
  'financialObjective.levelMapping.multiJurisdiction': {
    id: 'financialObjective.levelMapping.multiJurisdiction',
    defaultMessage: 'Multi jurisdiction'
  },
  'financialObjective.levelMapping.multiJurisdictionalComments': {
    id: 'financialObjective.levelMapping.multiJurisdictionalComments',
    defaultMessage: 'Multi jurisdictional comments'
  },
  'financialObjective.levelMapping.financeDirectors': {
    id: 'financialObjective.levelMapping.financeDirectors',
    defaultMessage: 'Finance Director'
  },
  'financialObjective.financialFigures.title': {
    id: 'financialObjective.financialFigures.title',
    defaultMessage: 'Financial Objectives (FO)'
  },
  'financialObjective.financialFigures.level1ProjectOnlyLabel': {
    id: 'financialObjective.financialFigures.level1ProjectOnlyLabel',
    defaultMessage: 'Own target ($DOP)'
  },
  'financialObjective.financialFigures.level2ProjectOnlyLabel': {
    id: 'financialObjective.financialFigures.level2ProjectOnlyLabel',
    defaultMessage: 'Next target ($SOP)'
  },
  'financialObjective.financialFigures.level1TotalContractLabel': {
    id: 'financialObjective.financialFigures.level1TotalContractLabel',
    defaultMessage: 'Own target (SOP)'
  },
  'financialObjective.financialFigures.level2TotalContractLabel': {
    id: 'financialObjective.financialFigures.level2TotalContractLabel',
    defaultMessage: 'Next target (SOP)'
  },
  'financialObjective.financialFigures.tradeWorkingCapitalARMainLabel': {
    id: 'financialObjective.financialFigures.tradeWorkingCapitalARMainLabel',
    defaultMessage: 'Trade Working Capital (AR)'
  },
  'financialObjective.financialFigures.contactTeamForUBRTarget': {
    id: 'financialObjective.financialFigures.contactTeamForUBRTarget',
    defaultMessage: 'contact your finance team for UBR target'
  },
  'financialObjective.financialFigures.contactTeamForARTarget': {
    id: 'financialObjective.financialFigures.contactTeamForARTarget',
    defaultMessage: 'contact your finance team for AR target'
  },
  'financialObjective.financialFigures.tradeWorkingCapitalUBRMainLabel': {
    id: 'financialObjective.financialFigures.tradeWorkingCapitalUBRMainLabel',
    defaultMessage: 'Trade Working Capital (UBR)'
  },
  'financialObjective.financialFigures.revenueMainLabel': {
    id: 'financialObjective.financialFigures.revenueMainLabel',
    defaultMessage: 'Revenue / Sales'
  },
  'financialObjective.financialFigures.label': {
    id: 'financialObjective.financialFigures.label',
    defaultMessage: 'GM/DOP/SOP'
  },
  'financialObjective.financialFigures.figure': {
    id: 'financialObjective.financialFigures.figure',
    defaultMessage: 'Figure'
  },
  'financialObjective.financialFigures.contribution': {
    id: 'financialObjective.financialFigures.contribution',
    defaultMessage: 'Contribution to bonus'
  },
  'financialObjective.financialFigures.amended': {
    id: 'financialObjective.financialFigures.amended',
    defaultMessage: ' . Amended'
  },
  'financialObjective.saveAsDraft': {
    id: 'financialObjective.saveAsDraft',
    defaultMessage: 'Save As Draft'
  },
  'financialObjective.submitForApproval': {
    id: 'financialObjective.submitForApproval',
    defaultMessage: 'Submit For Approval'
  },
  'financialObjective.validations.user': {
    id: 'financialObjective.validations.user',
    defaultMessage: 'User is required'
  },
  'financialObjective.validations.bonusSchemeId': {
    id: 'financialObjective.validations.bonusSchemeId',
    defaultMessage: 'Bonus Scheme is required'
  },
  'financialObjective.validations.cannotCreateFOForSelf': {
    id: 'financialObjective.validations.cannotCreateFOForSelf',
    defaultMessage: 'You cannot create FO for yourself'
  },
  'financialObjective.validations.notEligibleToCreateFO': {
    id: 'financialObjective.validations.notEligibleToCreateFO',
    defaultMessage: 'This employee is not eligible for a bonus scorecard in MyCBRE. Please confirm the employee\'s FMC information is accurate with your Finance Manager and confirm with your People Partner that eligibility is accurate in the PeopleSoft system. If both are correct then please log a Service now ticket'
  },
  'financialObjective.validations.startDate': {
    id: 'financialObjective.validations.startDate',
    defaultMessage: 'StartDate is required'
  },
  'financialObjective.validations.endDate': {
    id: 'financialObjective.validations.endDate',
    defaultMessage: 'EndDate is required'
  },
  'financialObjective.validations.status': {
    id: 'financialObjective.validations.status',
    defaultMessage: 'Status is required'
  },
  'financialObjective.validations.manualOverrideOtherCommentJustification': {
    id: 'financialObjective.validations.manualOverrideOtherCommentJustification',
    defaultMessage: 'Other Justification comment is required'
  },
  'financialObjective.validations.reasonForStatusChange': {
    id: 'financialObjective.validations.reasonForStatusChange',
    defaultMessage: 'Reason is required'
  },
  'financialObjective.validations.subSegment': {
    id: 'financialObjective.validations.subSegment',
    defaultMessage: 'SubSegment is required'
  },
  'financialObjective.validations.division': {
    id: 'financialObjective.validations.division',
    defaultMessage: 'Division is required'
  },
  'financialObjective.validations.vicePres': {
    id: 'financialObjective.validations.vicePres',
    defaultMessage: 'Vice Pres is required'
  },
  'financialObjective.validations.subDivision': {
    id: 'financialObjective.validations.subDivision',
    defaultMessage: 'Sub Division is required'
  },
  'financialObjective.validations.team': {
    id: 'financialObjective.validations.team',
    defaultMessage: 'Team is required'
  },
  'financialObjective.validations.client': {
    id: 'financialObjective.validations.client',
    defaultMessage: 'Client is required'
  },
  'financialObjective.validations.businessSegment': {
    id: 'financialObjective.validations.businessSegment',
    defaultMessage: 'Business Segment is Required'
  },
  'financialObjective.validations.globalRegion': {
    id: 'financialObjective.validations.globalRegion',
    defaultMessage: 'Global Region is Required'
  },
  'financialObjective.validations.region': {
    id: 'financialObjective.validations.region',
    defaultMessage: 'Region is Required'
  },
  'financialObjective.validations.country': {
    id: 'financialObjective.validations.country',
    defaultMessage: 'Country is Required'
  },
  'financialObjective.validations.managingOffice': {
    id: 'financialObjective.validations.managingOffice',
    defaultMessage: 'Managing Office is Required'
  },
  'financialObjective.validations.location': {
    id: 'financialObjective.validations.location',
    defaultMessage: 'Location is Required'
  },
  'financialObjective.validations.lineOfBusiness': {
    id: 'financialObjective.validations.lineOfBusiness',
    defaultMessage: 'Line Of Business is Required'
  },
  'financialObjective.validations.functionalUnit': {
    id: 'financialObjective.validations.functionalUnit',
    defaultMessage: 'Functional Unit is Required'
  },
  'financialObjective.validations.multiJurisdictionComment': {
    id: 'financialObjective.validations.multiJurisdictionComment',
    defaultMessage: 'Multi jurisdiction comment is required'
  },
  'financialObjective.validations.financeDirector': {
    id: 'financialObjective.validations.financeDirector',
    defaultMessage: 'Finance Director is required'
  },
  'financialObjective.validations.levelLabel': {
    id: 'financialObjective.validations.levelLabel',
    defaultMessage: 'Label is required'
  },
  'financialObjective.validations.levelFigure': {
    id: 'financialObjective.validations.levelFigure',
    defaultMessage: 'Figure is required'
  },
  'financialObjective.validations.levelContribution': {
    id: 'financialObjective.validations.levelContribution',
    defaultMessage: 'Contribution is required'
  },
  'financialObjective.validations.dateOverlapps': {
    id: 'financialObjective.validations.dateOverlapps',
    defaultMessage: 'Date range overlapps with existing FO form'
  },
  'financialObjective.validations.endDateInPartOrBeforeStartDate': {
    id: 'financialObjective.validations.endDateInPartOrBeforeStartDate',
    defaultMessage: 'End date should be in the past and greater than start date'
  },
  'financialObjective.nonFinancialObjectives.tabHeader': {
    id: 'financialObjective.nonFinancialObjectives.tabHeader',
    defaultMessage: 'Non-Financial Objectives Form'
  },
  'financialObjective.nonFinancialObjectives.subjectFieldLabel': {
    id: 'financialObjective.nonFinancialObjectives.subjectFieldLabel',
    defaultMessage: 'Goal Title'
  },
  'financialObjective.nonFinancialObjectives.assessmentFieldLabel': {
    id: 'financialObjective.nonFinancialObjectives.assessmentFieldLabel',
    defaultMessage: 'Line Manager Assessment'
  },
  'financialObjective.nonFinancialObjectives.attachmentsLabel': {
    id: 'financialObjective.nonFinancialObjectives.attachmentsLabel',
    defaultMessage: 'Attachments'
  },
  'financialObjective.nonFinancialObjectives.descriptionFieldLabel': {
    id: 'financialObjective.nonFinancialObjectives.descriptionFieldLabel',
    defaultMessage: 'Describe Actions You Will Take & Target Measures'
  },
  'financialObjective.nonFinancialObjectives.descriptionPlaceholder': {
    id: 'financialObjective.nonFinancialObjectives.descriptionPlaceholder',
    defaultMessage: 'Describe actions you will take & target measures'
  },
  'financialObjective.nonFinancialObjectives.selectText': {
    id: 'financialObjective.nonFinancialObjectives.selectText',
    defaultMessage: 'Select'
  },
  'financialObjective.nonFinancialObjectives.heading': {
    id: 'financialObjective.nonFinancialObjectives.heading',
    defaultMessage: 'Non-Financial Objectives'
  },
  'financialObjective.nonFinancialObjectives.header1': {
    id: 'financialObjective.nonFinancialObjectives.header1',
    defaultMessage: 'Non-Financial Objective 1'
  },
  'financialObjective.nonFinancialObjectives.header2': {
    id: 'financialObjective.nonFinancialObjectives.header2',
    defaultMessage: 'Non-Financial Objective 2'
  },
  'financialObjective.nonFinancialObjectives.header3': {
    id: 'financialObjective.nonFinancialObjectives.header3',
    defaultMessage: 'Non-Financial Objective 3'
  },
  'financialObjective.nonFinancialObjectives.header4': {
    id: 'financialObjective.nonFinancialObjectives.header4',
    defaultMessage: 'Non-Financial Objective 4'
  },
  'financialObjective.nonFinancialObjectives.header5': {
    id: 'financialObjective.nonFinancialObjectives.header5',
    defaultMessage: 'Non-Financial Objective 5'
  },
  'financialObjective.nonFinancialObjectives.saveNFOAssessment': {
    id: 'financialObjective.nonFinancialObjectives.saveNFOAssessment',
    defaultMessage: 'Save'
  },
  'financialObjective.nonFinancialObjectives.submitNFOAssessment': {
    id: 'financialObjective.nonFinancialObjectives.submitNFOAssessment',
    defaultMessage: 'Submit NFO Assessment'
  },
  'financialObjective.nonFinancialObjectives.submitNFOAssessmentMessage': {
    id: 'financialObjective.nonFinancialObjectives.submitNFOAssessmentMessage',
    defaultMessage: 'You are submitting the NFO assessment, once submitted you cannot alter this assessment. If you do not wish to proceed please cancel and press Save instead and submit at a later date.'
  },
  'financialObjective.nonFinancialObjectives.validations.subject': {
    id: 'financialObjective.nonFinancialObjectives.validations.subject',
    defaultMessage: 'Subject is required'
  },
  'financialObjective.nonFinancialObjectives.validations.description': {
    id: 'financialObjective.nonFinancialObjectives.validations.description',
    defaultMessage: 'Description is required'
  },
  'financialObjective.nonFinancialObjectives.validations.assessment': {
    id: 'financialObjective.nonFinancialObjectives.validations.assessment',
    defaultMessage: 'Assessment is required'
  },
  'financialObjective.nonFinancialObjectives.validationNumberOfNfoGoals': {
    id: 'financialObjective.nonFinancialObjectives.validationNumberOfNfoGoals',
    defaultMessage: 'You are unable to submit an assessment for less than 4 Non-Financial Objectives. If less than 4 NFO’s are displayed please check talent coach'
  },
  'financialObjective.nonFinancialObjectives.validationNfoAssessment': {
    id: 'financialObjective.nonFinancialObjectives.validationNfoAssessment',
    defaultMessage: 'All Non-Financial Objectives (NFOs) must be assessed before you are able to submit'
  },
  'nfoAttachments.uploadAttachments': {
    id: 'nfoAttachments.uploadAttachments',
    defaultMessage: 'Upload Attachments'
  },
  'nfoAttachments.invalidFileType': {
    id: 'nfoAttachments.invalidFileType',
    defaultMessage: 'Invalid file type'
  },
  'nfoAttachments.max5FilesAllowed': {
    id: 'nfoAttachments.max5FilesAllowed',
    defaultMessage: 'Maximum 5 files are allowed'
  },
  'nfoAttachments.max25MBAllowed': {
    id: 'nfoAttachments.max25MBAllowed',
    defaultMessage: 'Maximum file size allowed is 25MB'
  },
  'nfoAttachments.fileAlreadyExists': {
    id: 'nfoAttachments.fileAlreadyExists',
    defaultMessage: 'File already exists'
  },
  'nfoAttachments.maxFileNameLengthIs100': {
    id: 'nfoAttachments.maxFileNameLengthIs100',
    defaultMessage: 'Maximum file name length is 100 characters'
  },
  'financialObjective.validationAlert': {
    id: 'financialObjective.validationAlert',
    defaultMessage: 'Please fill both forms to submit for approval'
  },
    'previewFO.createdBy': {
    id: 'previewFO.createdBy',
    defaultMessage: 'Created By'
  },
  'previewFO.createdOn': {
    id: 'previewFO.createdOn',
    defaultMessage: 'Created On'
  },
  'previewFO.updatedBy': {
    id: 'previewFO.updatedBy',
    defaultMessage: 'Updated By'
  },
  'previewFO.updatedOn': {
    id: 'previewFO.updatedOn',
    defaultMessage: 'Updated On'
  },
  'previewFO.period1': {
    id: 'previewFO.period1',
    defaultMessage: 'Period'
  },
  'previewFO.level': {
    id: 'previewFO.level',
    defaultMessage: 'Level'
  },
  'previewFO.areaOfBusiness': {
    id: 'previewFO.areaOfBusiness',
    defaultMessage: 'Area of Business'
  },
  'previewFO.totalValue': {
    id: 'previewFO.totalValue',
    defaultMessage: 'Total Value'
  },
  'previewFO.contribution': {
    id: 'previewFO.contribution',
    defaultMessage: 'Contribution'
  },
  'previewFO.levelMappings': {
    id: 'previewFO.levelMappings',
    defaultMessage: 'Level Mappings'
  },
  'previewFO.financialObjectives': {
    id: 'previewFO.financialObjectives',
    defaultMessage: 'Financial Objectives'
  },
  'previewFO.approvalHistory': {
    id: 'previewFO.approvalHistory',
    defaultMessage: 'Approval History'
  },
  'previewFO.approvedForm': {
    id: 'previewFO.approvedForm',
    defaultMessage: 'Approved Form'
  },
  'previewFO.rejectedForm': {
    id: 'previewFO.rejectedForm',
    defaultMessage: 'Rejected Form'
  },
  'roles.roleAdded': {
    id: 'roles.roleAdded',
    defaultMessage: 'Role added successfully'
  },
  'roles.roleUpdated': {
    id: 'roles.roleUpdated',
    defaultMessage: 'Role updated successfully'
  },
  'roles.title': {
    id: 'roles.title',
    defaultMessage: 'Manage Roles'
  },
  'roles.subHeading': {
    id: 'roles.subHeading',
    defaultMessage: 'Roles'
  },
  'roles.role': {
    id: 'roles.role',
    defaultMessage: 'Role'
  },
  'roles.addRole': {
    id: 'roles.addRole',
    defaultMessage: 'Add Role'
  },
  'roles.newRole': {
    id: 'roles.newRole',
    defaultMessage: 'New Role'
  },
  'roles.roleName': {
    id: 'roles.roleName',
    defaultMessage: 'Role Name'
  },
  'roles.description': {
    id: 'roles.description',
    defaultMessage: 'Description'
  },
  'roles.privileges': {
    id: 'roles.privileges',
    defaultMessage: 'Privileges'
  },
  'roles.users': {
    id: 'roles.users',
    defaultMessage: 'Users'
  },
  'roles.roleAttributes': {
    id: 'roles.roleAttributes',
    defaultMessage: 'Role attributes'
  },
  'roles.searchPrivilege': {
    id: 'roles.searchPrivilege',
    defaultMessage: 'Search Privilege'
  },
  'roles.privilegeAdded': {
    id: 'roles.privilegeAdded',
    defaultMessage: 'Privilege added successfully'
  },
  'roles.privilegeRemoved': {
    id: 'roles.privilegeRemoved',
    defaultMessage: 'Privilege removed successfully'
  },
  'roles.searchUser': {
    id: 'roles.searchUser',
    defaultMessage: 'Search User'
  },
  'roles.userAdded': {
    id: 'roles.userAdded',
    defaultMessage: 'User added successfully'
  },
  'roles.userRemoved': {
    id: 'roles.userRemoved',
    defaultMessage: 'User removed successfully'
  },
  'roles.validations.name': {
    id: 'roles.validations.name',
    defaultMessage: 'Role name is required'
  },
  'roles.validations.uniqueName': {
    id: 'roles.validations.uniqueName',
    defaultMessage: 'Role name should be unique'
  },
  'menu.manageRoles': {
    id: 'menu.manageRoles',
    defaultMessage: 'Manage Roles'
  },
  'menu.manageUsers': {
    id: 'menu.manageUsers',
    defaultMessage: 'Manage Users'
  },
  'menu.administration': {
    id: 'menu.administration',
    defaultMessage: 'Administration'
  },
  'menu.calculator': {
    id: 'menu.calculator',
    defaultMessage: 'Calculator'
  },
  'menu.reports': {
    id: 'menu.reports',
    defaultMessage: 'Reports'
  },
  'menu.tm1Upload': {
    id: 'menu.tm1Upload',
    defaultMessage: 'TM1 Upload'
  },
  'menu.calculatorDataPreview': {
    id: 'menu.calculatorDataPreview',
    defaultMessage: 'Calculator'
  },
  'menu.financeBonusSchemeRules': {
    id: 'menu.financeBonusSchemeRules',
    defaultMessage: 'Finance Bonus Scheme Rules'
  },
  'menu.localBonusSchemeRules': {
    id: 'menu.localBonusSchemeRules',
    defaultMessage: 'Local Bonus Scheme Rules'
  },
  'menu.userGuide': {
    id: 'menu.userGuide',
    defaultMessage: 'User Guide'
  },
  'menu.nfoAssessmentGuide': {
    id: 'menu.nfoAssessmentGuide',
    defaultMessage: 'NFO Assessment Guide'
  },
  'menu.updateDocumentation': {
    id: 'menu.updateDocumentation',
    defaultMessage: 'Update Documentation'
  },
  'users.title': {
    id: 'users.title',
    defaultMessage: 'Manage Users'
  },
  'users.label': {
    id: 'users.label',
    defaultMessage: 'Users'
  },
  'users.searchUserLabel': {
    id: 'users.searchUserLabel',
    defaultMessage: 'Search by Name or Id'
  },
  'users.userDetails': {
    id: 'users.userDetails',
    defaultMessage: 'User details'
  },
  'users.employeeId': {
    id: 'users.employeeId',
    defaultMessage: 'Employee ID'
  },
  'users.employeeFirstName': {
    id: 'users.employeeFirstName',
    defaultMessage: 'First Name'
  },
  'users.employeeLastName': {
    id: 'users.employeeLastName',
    defaultMessage: 'Last Name'
  },
  'users.employeeJobTitle': {
    id: 'users.employeeJobTitle',
    defaultMessage: 'Job Title'
  },
  'users.employeeEmail': {
    id: 'users.employeeEmail',
    defaultMessage: 'Email'
  },
  'users.employeeManager': {
    id: 'users.employeeManager',
    defaultMessage: 'Manager'
  },
  'users.roles': {
    id: 'users.roles',
    defaultMessage: 'Roles'
  },
  'users.roleAdded': {
    id: 'users.roleAdded',
    defaultMessage: 'Role added successfully'
  },
  'users.roleRemoved': {
    id: 'users.roleRemoved',
    defaultMessage: 'Role removed successfully'
  },
  'users.scope': {
    id: 'users.scope',
    defaultMessage: 'Scope'
  },
  'users.scopeUpdated': {
    id: 'users.scopeUpdated',
    defaultMessage: 'User scope updated successfully'
  },
  'users.higherScopeValidationMsg': {
    id: 'users.higherScopeValidationMsg',
    defaultMessage: 'You can not assign higher scopes then your assigned scopes'
  },
  'users.differentScopeAssignedValidationMsg': {
    id: 'users.differentScopeAssignedValidationMsg',
    defaultMessage: 'You cannot assign scopes as the user has different scopes assigned'
  },
  'ScoreCardEvents.section': {
    id: 'ScoreCardEvents.section',
    defaultMessage: 'Section'
  },
  'ScoreCardEvents.field': {
    id: 'ScoreCardEvents.field',
    defaultMessage: 'Field'
  },
  'ScoreCardEvents.oldValue': {
    id: 'ScoreCardEvents.oldValue',
    defaultMessage: 'Old Value'
  },
  'ScoreCardEvents.newValue': {
    id: 'ScoreCardEvents.newValue',
    defaultMessage: 'New Value'
  },
  'ScoreCardEvents.updatedBy': {
    id: 'ScoreCardEvents.updatedBy',
    defaultMessage: 'Updated By'
  },
  'ScoreCardEvents.updatedOn': {
    id: 'ScoreCardEvents.updatedOn',
    defaultMessage: 'Updated On'
  },
  'ScoreCardEvents.title': {
    id: 'ScoreCardEvents.title',
    defaultMessage: 'Scorecard Audit History'
  },
  'ScoreCardEvents.noData': {
    id: 'ScoreCardEvents.noData',
    defaultMessage: 'No information available'
  },
  'ScoreCardEvents.subSection': {
    id: 'ScoreCardEvents.subSection',
    defaultMessage: 'Sub Section'
  },
  'ScoreCardEvents.FinancialObjectivesForm': {
    id: 'ScoreCardEvents.FinancialObjectivesForm',
    defaultMessage: 'Financial Objectives Form'
  },
  'ScoreCardEvents.LevelMappings': {
    id: 'ScoreCardEvents.LevelMappings',
    defaultMessage: 'Level Mappings'
  },
  'ScoreCardEvents.Scope': {
    id: 'ScoreCardEvents.Scope',
    defaultMessage: 'Scope'
  },
  'ScoreCardEvents.FinancialObjectives': {
    id: 'ScoreCardEvents.FinancialObjectives',
    defaultMessage: 'Financial Objectives'
  },
  'ScoreCardEvents.NonFinancialObjective1': {
    id: 'ScoreCardEvents.NonFinancialObjective1',
    defaultMessage: 'Non-Financial Objective 1'
  },
  'ScoreCardEvents.NonFinancialObjective2': {
    id: 'ScoreCardEvents.NonFinancialObjective2',
    defaultMessage: 'Non-Financial Objective 2'
  },
  'ScoreCardEvents.NonFinancialObjective3': {
    id: 'ScoreCardEvents.NonFinancialObjective3',
    defaultMessage: 'Non-Financial Objective 3'
  },
  'ScoreCardEvents.NonFinancialObjective4': {
    id: 'ScoreCardEvents.NonFinancialObjective4',
    defaultMessage: 'Non-Financial Objective 4'
  },
  'ScoreCardEvents.NonFinancialObjective5': {
    id: 'ScoreCardEvents.NonFinancialObjective5',
    defaultMessage: 'Non-Financial Objective 5'
  },
  'ScoreCardEvents.StartDate': {
    id: 'ScoreCardEvents.StartDate',
    defaultMessage: 'StartDate'
  },
  'ScoreCardEvents.EndDate': {
    id: 'ScoreCardEvents.EndDate',
    defaultMessage: 'EndDate'
  },
  'ScoreCardEvents.BonusScheme': {
    id: 'ScoreCardEvents.BonusScheme',
    defaultMessage: 'Bonus Scheme'
  },
  'ScoreCardEvents.Status': {
    id: 'ScoreCardEvents.Status',
    defaultMessage: 'Status'
  },
  'ScoreCardEvents.StatusChangeComment': {
    id: 'ScoreCardEvents.StatusChangeComment',
    defaultMessage: 'Status Change Comment'
  },
  'ScoreCardEvents.ScopeType': {
    id: 'ScoreCardEvents.ScopeType',
    defaultMessage: 'Scope'
  },
  'ScoreCardEvents.ScopeComment': {
    id: 'ScoreCardEvents.ScopeComment',
    defaultMessage: 'Scope Comment'
  },
  'ScoreCardEvents.OwnTargetFigure': {
    id: 'ScoreCardEvents.OwnTargetFigure',
    defaultMessage: 'Own Target Figure'
  },
  'ScoreCardEvents.NextTargetFigure': {
    id: 'ScoreCardEvents.NextTargetFigure',
    defaultMessage: 'Next Target Figure'
  },
  'ScoreCardEvents.RevenueFigure': {
    id: 'ScoreCardEvents.RevenueFigure',
    defaultMessage: 'Revenue Figure'
  },
  'ScoreCardEvents.TradeWorkingCapitalARFigure': {
    id: 'ScoreCardEvents.TradeWorkingCapitalARFigure',
    defaultMessage: 'Trade Working Capital (AR) Figure'
  },
  'ScoreCardEvents.SubSegment': {
    id: 'ScoreCardEvents.SubSegment',
    defaultMessage: 'Sub Segment'
  },
  'ScoreCardEvents.Division': {
    id: 'ScoreCardEvents.Division',
    defaultMessage: 'Division'
  },
  'ScoreCardEvents.SubDivision': {
    id: 'ScoreCardEvents.SubDivision',
    defaultMessage: 'Sub Division'
  },
  'ScoreCardEvents.Team': {
    id: 'ScoreCardEvents.Team',
    defaultMessage: 'Team'
  },
  'ScoreCardEvents.Country': {
    id: 'ScoreCardEvents.Country',
    defaultMessage: 'Country'
  },
  'ScoreCardEvents.Client': {
    id: 'ScoreCardEvents.Client',
    defaultMessage: 'Client'
  },
  'ScoreCardEvents.ManualOverrideOtherCommentJustification': {
    id: 'ScoreCardEvents.ManualOverrideOtherCommentJustification',
    defaultMessage: 'ManualOverride Other Comment Justification'
  },
  'ScoreCardEvents.ManualOverrideComment': {
    id: 'ScoreCardEvents.ManualOverrideComment',
    defaultMessage: 'Manual OverrideComment'
  },
  'ScoreCardEvents.OwnTargetLabel': {
    id: 'ScoreCardEvents.OwnTargetLabel',
    defaultMessage: 'Own Target Label'
  },
  'ScoreCardEvents.NextTargetLabel': {
    id: 'ScoreCardEvents.NextTargetLabel',
    defaultMessage: 'Next Target Label'
  },
  'ScoreCardEvents.RevenueLabel': {
    id: 'ScoreCardEvents.RevenueLabel',
    defaultMessage: 'Revenue Label'
  },
  'ScoreCardEvents.TradeWorkingCapitalARLabel': {
    id: 'ScoreCardEvents.TradeWorkingCapitalARLabel',
    defaultMessage: 'Trade Working Capital (AR) Label'
  },
  'ScoreCardEvents.Subject': {
    id: 'ScoreCardEvents.Subject',
    defaultMessage: 'Subject'
  },
  'ScoreCardEvents.Description': {
    id: 'ScoreCardEvents.Description',
    defaultMessage: 'Description'
  },
  'ScoreCardEvents.Assessment': {
    id: 'ScoreCardEvents.Assessment',
    defaultMessage: 'Assessment'
  },
  'ScoreCardEvents.FO': {
    id: 'ScoreCardEvents.FO',
    defaultMessage: 'FO'
  },
  'ScoreCardEvents.NFO': {
    id: 'ScoreCardEvents.NFO',
    defaultMessage: 'NFO'
  },
  'ScoreCardEvents.TradeWorkingCapitalUBRLabel': {
    id: 'ScoreCardEvents.TradeWorkingCapitalUBRLabel',
    defaultMessage: 'Trade Working Capital (UBR) Label'
  },
  'ScoreCardEvents.TradeWorkingCapitalUBRFigure': {
    id: 'ScoreCardEvents.TradeWorkingCapitalUBRFigure',
    defaultMessage: 'TradeWorkingCapital (UBR) Figure'
  },
  'documentUpload.downloadError': {
    id: 'documentUpload.downloadError',
    defaultMessage: 'NFO Guide / User Guide - not available, please try again later or contact System Admin.'
  },
});
