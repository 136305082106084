import { ErrorCode } from 'models/error';

export const onValidate = (values: any) => {
  const errors: any = {};

  if (values.document == null) {
    errors.document = 'documentUpload.validation.requiredFile';
  }
  return errors;
};

export const serverErrors = (serverErrors: any) => {
  const errors: any = {};

  if (serverErrors?.length > 0) {   
    const documentError = serverErrors.find((x: any) => x.Code === ErrorCode.unSupportedFile);
    if (documentError) {
      errors.document = 'documentUpload.validation.unSupportedFile';
    }
  }
  return errors;
};
