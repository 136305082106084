import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CloudUploadOutlined, Delete } from '@material-ui/icons';
import { FileUploadMode } from '../../models/attachment';
import { UploadType } from 'models/tmUpload';

interface PresentationalProps {
  dragging: boolean;
  fileErrorMessage: any;
  file: File;
  uploadType: UploadType;
  fileUploadMode: FileUploadMode | FileUploadMode.SingleFileSingleUpload;
  isLoading: boolean | false;
  onSelectFileClick: () => void;
  onDrag: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragStart: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragEnd: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragEnter: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  deleteFile: (type: UploadType) => void;
  isDisabled: boolean;
  errorKey?: string;
}
export const FileUploaderPresentationalComponent: React.SFC<PresentationalProps> = (props) => {
  const {
    dragging,
    fileErrorMessage,
    onSelectFileClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onDragOver,
    onDragEnter,
    onDragLeave,
    onDrop,
    isLoading
  } = props;

  let uploaderClasses = 'file-uploader';
  if (dragging) {
    uploaderClasses += ' file-uploader--dragging';
  }

  const uploaderPClasses = 'col-md-12';

  const getUploaderClass = () => {
    return (
      uploaderClasses + (props.fileUploadMode === FileUploadMode.SingleFileSingleUpload && props.file ? ' hide' : '')
    );
  };
  const getUploaderpClass = () => {
    return (
      uploaderPClasses + (props.fileUploadMode === FileUploadMode.SingleFileSingleUpload && props.file ? ' hide' : '')
    );
  };

  const getBrowserButtonClass = () => {
    return 'edit-btn' + (props.isLoading ? ' disabled' : '');
  };

  return (
    <>
      <div className="row uploader">
        <div className={getUploaderpClass()}>
          <div
            className={getUploaderClass()}
            onDrag={onDrag}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDragOver={onDragOver}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
          >
            {/* condition to show loader while uploading file */}
            {isLoading ? (
              <div className="file-upload-loader">
                <p>
                  <FormattedMessage id="attachments.loadingMessage" />
                </p>
                <div className="file-upload-loader d-flex justify-content-center">
                  <progress></progress>
                </div>
              </div>
            ) : (
              <div className="file-uploader__contents">
                <span className="text-danger">
                  {fileErrorMessage}
                  {props.errorKey && <FormattedMessage id={props.errorKey} />}
                </span>
                <CloudUploadOutlined />
                <span className="drag_drop">
                  <FormattedMessage id="attachments.dragAndDrop" />
                </span>
                <span className="drag_drop_or">
                  <FormattedMessage id="attachments.or" />
                </span>
                <span onClick={onSelectFileClick} className={props.isDisabled ? 'link_disabled' : ''}>
                  <button
                    type="button"
                    className={`buttonHover ${getBrowserButtonClass()}`}
                    disabled={props.isDisabled}
                  >
                    <FormattedMessage id="attachments.browseMyComputer" />
                  </button>
                </span>
              </div>
            )}
            {props.children}
          </div>
        </div>
      </div>
      {props.file && (
        <div className="row file-detail">
          <div className="col-md-10 upload-file-name-wrap">{props.file.name}</div>
          <div className="col-md-2">
            <button
              id="deleteFile"
              type="button"
              className="btn btn-outline icoBtn"
              onClick={() => props.deleteFile(props.uploadType)}
            >
              <Delete />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FileUploaderPresentationalComponent;
