import { UploadType } from "./tmUpload";

export interface IDocumentUpload {
    document: File;
    financialYear: number;
    type:string
  }
  
   export enum DocumentationType {
     UserGuide = 'UserGuide',
     NFOGuide = 'NFOGuide'
   }
  
  export interface IDocumentUploadItem {
    id: string;
    name:string;    
    documentType: string;
    createdBy: string;
    createdOn: string;
  }
  