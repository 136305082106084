import { createSelector } from 'reselect';
import { AppState } from 'state/app-state';

const getDocumentUploadContent = (state: AppState) => state.documentUpload;

export const getDocumentUploadLoadingState = createSelector(getDocumentUploadContent, (documentUploadContent) => documentUploadContent.loading);

export const getDocumentUploadEventState = createSelector(getDocumentUploadContent, (documentUploadContent) => documentUploadContent.event);

export const getDocumentUploadNotifyState = createSelector(getDocumentUploadContent, (documentUploadContent) => documentUploadContent.notify);

export const getErrorsState = createSelector(getDocumentUploadContent, (documentUploadContent) => documentUploadContent.errors);

export const getDocumentUploadItemsState = createSelector(
    getDocumentUploadContent,
  (documentUploadContent) => documentUploadContent.documentUploadItems
);
