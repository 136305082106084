import { IDocumentUploadItem } from 'models/documentUpload';
import { IError } from 'models/error';
import { EventType } from 'models/EventType';
import { Notify } from 'models/Notify';

export interface DocumentUploadState {
  loading: boolean;
  success: boolean;
  event: EventType;
  notify: Notify;
  errors: Array<IError>;
  documentUploadItems: Array<IDocumentUploadItem>;
}

export const defaultDocumentUploadState: DocumentUploadState = {
  loading: false,
  success: false,
  event: EventType.none,
  notify: Notify.none,
  errors: [],
  documentUploadItems: []
};
