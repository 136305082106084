import React from 'react';
import { render } from 'react-dom';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import messages from 'translations/messages';

interface IProps {
  intl: any;
  titleKey?: string;
  contentKey: string;
  handleConfirm?: () => void;
  handleReject?: () => void;
  showFooter?: boolean;
  showOkButtonOnly?: boolean;
  buttonYesKey?:string;
  buttonNoKey?:string;
}

const CustomModalPopup: React.FC<IProps & InjectedIntlProps> = (props) => {
  const confirm = () => {
    if (props.handleConfirm) {
      props.handleConfirm();
    }
    removeBodyClass();
  };
  const reject = () => {
    if (props.handleReject) {
      props.handleReject();
    }
    removeBodyClass();
  };
  const removeBodyClass = () => {
    document.body.classList.remove('react-confirm-alert-body-element');
    const target: any = document.getElementById('react-confirm-alert');
    target.parentNode.removeChild(target);
  };
  return (
    <div
      className="modal fade show custom_modal"
      id="exampleModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      data-keyboard="false"
      data-backdrop="static"
      style={{ display: 'block' }}
      aria-modal="true"
    >
      <div className="vertical-alignment-helper h-100">
        <div className="modal-dialog modal-dialog-centered vertical-align-center" role="document">
          <div className="modal-content d-flex align-items-center">
            <div className="modal-header">
              {props.titleKey !== undefined && (
                <h5 className="modal-title" id="exampleModalLabel">
                  {props.intl.formatMessage(messages[props.titleKey ? props.titleKey : 'global.confirmation'])}
                </h5>
              )}
              <span onClick={() => reject()} data-test="close_icon" className="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </span>
            </div>
            <div className="modal-body">
              <p>{props.intl.formatMessage(messages[props.contentKey])}</p>
            </div>
            {(props.showFooter === undefined || props.showFooter === true) && (
              <div className="modal-footer">
                <button type="button" data-test="button_reject" className="btn btn-light" onClick={() => reject()}>
                  {props.intl.formatMessage(messages[props.buttonNoKey ? props.buttonNoKey : 'global.buttonNo'])}
                </button>
                <button type="button" data-test="button_confirm" className="btn btn-dark" onClick={() => confirm()}>
                  {props.intl.formatMessage(messages[props.buttonYesKey ? props.buttonYesKey :'global.buttonYes'])}
                </button>
              </div>
            )}
            {(props.showFooter === false && props.showOkButtonOnly === true) && (
              <div className="modal-footer">
                <button type="button" data-test="button_confirm" className="btn btn-dark" onClick={() => confirm()}>
                  OK
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function createElementReconfirm(properties: any) {
  let divTarget = document.getElementById('react-confirm-alert');
  if (divTarget) {
    render(<CustomModalPopup {...properties} />, divTarget);
  } else {
    if (document.body.children.length > 0) {
      document.body.children[0].classList.add('react-confirm-alert-blur');
    }
    divTarget = document.createElement('div');
    divTarget.id = 'react-confirm-alert';
    document.body.appendChild(divTarget);
    render(<CustomModalPopup {...properties} />, divTarget);
  }
}

export function confirmAlert(properties: any) {
  createElementReconfirm(properties);
}
export default injectIntl(CustomModalPopup);
