import * as React from 'react';
import { DocumentUploadContainer } from 'sections/documentation';

class UpdateDocumentation extends React.Component {
  public render() {
    return <DocumentUploadContainer />;
  }
}

export default UpdateDocumentation;
