import { formatDecimalCurrency, formatDecimalNumber, formatFinancialFigure, formatNumber } from 'helpers/form-helper';
import React, { useRef } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

interface Props {
  input: any;
  labelKey: any;
  type?: any;
  name: any;
  placeholderKey?: any;
  meta: any;
  className?: any;
  intl: any;
  readOnly?: any;
  disable?: any;
  errorKey?: string;
  fieldPrefix?: string;
  fieldSuffix?: string;
  additionalLabel?: string;
  formatCurrency?: boolean;
  isNumeric?: boolean;
  isDecimalNumeric?: boolean;
  isDecimalCurrency?: boolean;
  onKeyDown?: any;
}

const FormInput = (props: Props) => {
  const element = useRef<HTMLInputElement>(null);

  const errorClass = `${(props.meta.error && props.meta.touched) || props.meta.warning ? 'error' : ''}`;
  const _placeholder = props.placeholderKey
    ? props.intl.formatMessage({ id: props.placeholderKey })
    : props.placeholderKey;

  const validate = (e: any) => {
    if (props.formatCurrency) {
      const elmt = element.current;
      if (elmt && elmt.value.toString() !== '') {
        elmt.value = formatFinancialFigure(elmt.value);
      }
    } else if (props.isNumeric) {
      const elmt = element.current;
      if (elmt && elmt.value.toString() !== '') {
        elmt.value = formatNumber(elmt.value).toString();
      }
    }
  };

  const validateOnBlur = (e: any) => {
    if (props.isDecimalCurrency) {
      const elmt = element.current;
      if (elmt && elmt.value.toString() !== '') {
        elmt.value = formatDecimalCurrency(elmt.value);
      }
    } else if (props.isDecimalNumeric) {
      const elmt = element.current;
      if (elmt && elmt.value.toString() !== '') {
        elmt.value = formatDecimalNumber(elmt.value).toString();
      }
    }
  };

  return (
    <>
      <div className="form-group">
        <div className="input-outline">
          <div className={`input-content-outline${props.disable ? ' disabled' : ''}`}>
            {props.fieldPrefix && <span className="fieldPrefix">{props.fieldPrefix}</span>}
            <input
              {...props.input}
              ref={element}
              placeholder={_placeholder}
              type={props.type}
              readOnly={props.readOnly}
              className={'form-control ' + props.className + ' ' + errorClass}
              data-test={'inputComponent'}
              disabled={props.disable}
              autoComplete="off"
              onInput={validate}
              onBlur={validateOnBlur}
              onKeyDown={props.onKeyDown}
            />
            {props.fieldSuffix && <span className="fieldSuffix">{props.fieldSuffix}</span>}
          </div>
          <div className="input-label">
            {props.labelKey && <label className="input-label-text">{<FormattedMessage id={props.labelKey} />}</label>}
            {props.additionalLabel && props.additionalLabel !== '' ? (
              <label className="input-label-text">{<FormattedMessage id={props.additionalLabel} />}</label>
            ) : (
              <></>
            )}
          </div>
        </div>
        {props.meta.touched &&
          ((props.meta.error && <span className="text-danger">{props.meta.error}</span>) ||
            (props.meta.warning && <span className="text-danger">{props.meta.warning}</span>))}
        {props.errorKey && (
          <span className="text-danger">
            <FormattedMessage id={props.errorKey} />
          </span>
        )}
      </div>
    </>
  );
};

export default injectIntl(FormInput);
export const formInput = injectIntl(FormInput);
