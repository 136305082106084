import { defaultDocumentUploadState, DocumentUploadState } from './state';
import { AppAction } from 'models/action';
import { actionTypes } from './actions';
import { Notify } from 'models/Notify';
import { EventType } from 'models/EventType';

export const handlers = {
  [actionTypes.SET_DOCUMENTUPLOAD_LOADING_TRUE]: (state: DocumentUploadState, payload: any): DocumentUploadState => {
    return {
      ...state,
      loading: true,
      event: payload.event
    };
  },
  [actionTypes.RESET_DOCUMENTUPLOAD_STATE]: (state: DocumentUploadState, payload: any): DocumentUploadState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: Notify.none,
      event: EventType.none,
      errors: []
    };
  },

  [actionTypes.GET_HISTORY_SUCCESS]: (state: DocumentUploadState, payload: any): DocumentUploadState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      documentUploadItems: payload.items
    };
  },
  [actionTypes.GET_HISTORY_ERROR]: (state: DocumentUploadState, payload: any): DocumentUploadState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify
    };
  },
  [actionTypes.DOWNLOAD_SUCCESS]: (state: DocumentUploadState, payload: any): DocumentUploadState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none
    };
  },
  [actionTypes.DOWNLOAD_ERROR]: (state: DocumentUploadState, payload: any): DocumentUploadState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify
    };
  },
  [actionTypes.DOCUMENT_UPLOAD_SUCCESS]: (state: DocumentUploadState, payload: any): DocumentUploadState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.success,
      event: payload.event,
      errors: []
    };
  },
  [actionTypes.DOCUMENT_UPLOAD_ERROR]: (state: DocumentUploadState, payload: any): DocumentUploadState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: payload.event,
      errors: payload.errors
    };
  },
};

const DocumentUploadReducer = (state: DocumentUploadState = { ...defaultDocumentUploadState }, action: AppAction) => {
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action.payload) : state;
};

export default DocumentUploadReducer;
