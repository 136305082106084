import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field } from 'redux-form';
import formInput from './formInput';

export function formSelect({
  input,
  labelKey,
  meta: { touched, error, warning },
  children,
  DropdownCheck,
  disabled,
  otherFieldName,
  otherFieldLabelKey,
  otherFieldPlaceHolderKey,
  validationMessage,
  errorKey,
}: {
  input: any;
  labelKey: any;
  meta: { touched: any; error: any; warning: any };
  children: any;
  DropdownCheck: any;
  disabled: any;
  otherFieldName: any;
  otherFieldLabelKey: any;
  otherFieldPlaceHolderKey: any;
  validationMessage: any;
  errorKey: string;
}) {
  const isOtherOption = (input?.value === 0 || input?.value === '0') && otherFieldName ? true : false;

  const errorClass = `${error && touched ? 'error' : ''}`;

  return (
    <React.Fragment>
      <div className="form-group">
        <div className="input-outline">
          <div className="input-content-outline select-dropdown">
            <select
              className={'form-control ' + errorClass + ' ' + DropdownCheck}
              {...input}
              disabled={disabled}
              data-test={'selectComponent'}
              value={input?.value}
            >
              {children}
            </select>
          </div>
          <div className="input-label">
            {labelKey && <label className="input-label-text">{<FormattedMessage id={labelKey} />}</label>}
          </div>
        </div>
        {errorKey && (
          <span className="text-danger">
            <FormattedMessage id={errorKey} />
          </span>
        )}
        {touched && error && validationMessage ? (
          <span className="text-danger">{validationMessage}</span>
        ) : (
          touched && error && <span className="text-danger">{error}</span>
        )}
      </div>

      {isOtherOption && (
        <Field
          name={otherFieldName}
          type="text"
          component={formInput}
          className="required"
          labelKey={otherFieldLabelKey}
          placeholderKey={otherFieldPlaceHolderKey}
        />
      )}
    </React.Fragment>
  );
}

export default injectIntl(formSelect);
export const FormSelect = injectIntl(formSelect);
