import React, { useEffect, useMemo, useState } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { onErrorScrollToFormField, Validate } from 'helpers/form-helper';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import FileUploader from 'common/attachment/CommonFileUpload';
import { FileUploadMode } from 'models/attachment';
import './../../../common/formFields/form.scss';
import { DocumentationType, IDocumentUpload } from 'models/documentUpload';
import {UploadType} from 'models/tmUpload'
import { ErrorCode, IError } from 'models/error';
import { onValidate, serverErrors } from '../documentUploadHelper';
import { EventType } from 'models/EventType';
import { CircularProgress } from '@material-ui/core';
import { getFinancialYear } from '../../../helpers/helper';
import { isFMCFinancialYear } from 'helpers/helper';
import formSelect from 'common/formFields/formSelect';

interface Props extends InjectedIntlProps {
  loading: boolean;
  event: EventType;
  documentData: File;
  documentType:UploadType;
  errors: Array<IError>;
  postDocument: (data: IDocumentUpload) => void;
  setDocumentData: (document: File | null) => void;
}

const DocumentUploadFormComponent = (props: Props & InjectedFormProps<any, Props>) => {
  const [formErrors, setFormErrors] = useState<any>({});
  const [inValidFile, setInValidFile] = useState<string>('');

  useEffect(() => {
    const errors = serverErrors(props.errors);
    setFormErrors(errors);
    const inValidFile = props.errors.find((x: any) => x.Code === ErrorCode.inValidFile);
    if (inValidFile) {
      setInValidFile(inValidFile.Message);
    }
  }, [props.errors]);

  const onSave = (data: any) => {   
    const formData = formatData(data);
    const errors = onValidate(formData);
    if (errors && Object.keys(errors).length > 0) {
      setFormErrors(errors);
      onErrorScrollToFormField(errors);
    } else {
      setFormErrors({});
      props.postDocument(formData);
    }
  };

  const financialYear = getFinancialYear();

  const formatData = (data: any) => {
    const formData: IDocumentUpload = {
      ...data,
      financialYear: financialYear,
      document: props.documentData,
      type: data.documentType?.toString(),
    };
    return formData;
  };

  const uploadFile = (file: File, type: UploadType) => {
    switch (type) {
      case UploadType.UserGuide:
        props.setDocumentData(file);
        setFormErrors({ ...formErrors, document: undefined });
        break;
    }
  };

  const deleteFile = (type: UploadType) => {
    props.setDocumentData(null);
        setInValidFile('');
  };

  return (
    <>
      <form className="documentUpload-form" style={{ width: '100%' }} onSubmit={props.handleSubmit}>
        <h2 className="subheading medium-fw">
          <FormattedMessage id="documentUpload.details" />
        </h2>
        <div className="row">   
          <div className='col-md-6'>
          <Field
              name="documentType"
              id="documentType"
              component={formSelect}
              disabled={false}
              labelKey="documentUpload.documentType"
              validate={Validate.required(
                props.intl?.formatMessage({ id: 'documentUpload.validation.requiredDocumentType' })
              )}
              errorKey={formErrors?.documentType ?? undefined}
            >
              <FormattedMessage id="documentUpload.selectDocumentType">
                {(message) => <option value="">{message}</option>}
              </FormattedMessage>
              {Object.keys(DocumentationType).map((key: string, i: number) => {
                return (
                  <option key={i} value={key}>
                    {props.intl.formatMessage({ id: `documentUpload.${key}`})}                    
                  </option>
                );
              })}
            </Field>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h3 className="subheading medium-fw">
                <FormattedMessage id="documentUpload.documentData" />
            </h3>
            <FileUploader
                id="documentData"
                isLoading={false}
                file={props.documentData}
                uploadType={UploadType.UserGuide}
                allowedFileTypes=".pdf"
                invalidFileTypeMessage={props.intl.formatMessage({ id: 'documentUpload.validation.unSupportedFile' })}
                fileUploadMode={FileUploadMode.SingleFileSingleUpload}
                singleColumn={true}
                uploadFile={uploadFile}
                deleteFile={deleteFile}
                isDisabled={false}
                errorKey={formErrors?.document ?? undefined}
            />
            {inValidFile != '' && (
                <div className="justify-content-center">
                    <span className="text-danger">{inValidFile}</span>
                </div>
            )}
          </div>          
        </div>
        
        <div className="row">
          <div className="cta right_align mb-3 mt-3">
            <button
              id="uploadFile"
              className="btn btn-dark"
              onClick={props.handleSubmit((values: any) => onSave(values))}
              disabled={props.loading || !isFMCFinancialYear()}
            >
              {props.loading && props.event === EventType.save && (
                <CircularProgress className="sbl-circ-path" thickness={4} value={100} />
              )}
              <FormattedMessage id="global.unformattedSave" />
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

const form = reduxForm<any, any>({
  form: 'DocumentUploadForm',
  enableReinitialize: true
})(injectIntl(DocumentUploadFormComponent));

export const DocumentUploadForm = form;
