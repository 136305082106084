export default {
  home: '/',
  guidelines: '/guidelines',
  financialObjective: '/financialobjective',
  previewFO: '/preview',
  roles: '/roles',
  calculator: '/calculator',
  users: '/users',
  accessDenied: '/accessdenied',
  notFound: '/notFound',
  employees: '/employees',
  tm1Upload: '/tm1Upload',
  manageReport: '/managereport',
  updateDocumentation: '/updateDocumentation'
};
